import { sentenceCase } from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { PermissionLink } from '@/components';
import { FLEET_PERMISSIONS } from '../../../const/permissions';
import fleetRoutes from '../../../router/FleetRouterMap';

export function contentCells() {
  return [
    {
      attributeName: 'id',
      title: 'ID',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        to: {
          name: fleetRoutes.vehicleSettings.models.detail,
          params: {
            vehicleModelUuid: result.uuid,
          },
        },
        linkPermissions: [FLEET_PERMISSIONS.viewCarsharingVehicles],
        text: result.id,
      }),
      minWidth: 100,
    },
    {
      attributeName: 'vehicle_brand',
      title: 'Vehicle brand',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      attributeName: 'name',
      title: 'Model name',
      displayPriority: 1,
      minWidth: 150,
    },
    {
      attributeName: 'fuel_type',
      title: 'Fuel type',
      displayPriority: 1,
      minWidth: 150,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'doors_number',
      title: 'Doors',
      displayPriority: 1,
      minWidth: 80,
    },
    {
      attributeName: 'picture',
      title: 'Picture',
      displayPriority: 1,
      minWidth: 200,
      type: RECORD_TYPES.template,
      template: result => `<img src="${result.picture}" alt="picture" style="max-height: 60px; width: auto; height: 100%;" />`,
    },
  ];
}
