<script>
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { sentenceCase } from '@emobg/web-utils';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    vehicleModel: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    text() {
      const vehicleName = `${get(this, 'vehicleModel.vehicleBrand')} ${get(this, 'vehicleModel.name')} (Id ${get(this, 'vehicleModel.id')})`;
      const fuelType = sentenceCase(get(this, 'vehicleModel.fuelType', ''));
      return `${vehicleName} - Fuel type ${lowerCase(fuelType)} - ${get(this, 'vehicleModel.doorsNumber')} doors`;
    },
    ...mapState(DOMAINS_MODEL.fleet.vehicleModels, {
      deleteModelStatus: state => state.deleteModel.STATUS,
    }),
  },
  methods: {
    async removeVehicleModel() {
      await this.deleteVehicleModel(this.vehicleModel.uuid);
      this.$emit('closeModal');
      if (!this.deleteModelStatus.ERROR) {
        this.$notify({
          message: 'Model deleted successfully!',
          textAction: '',
        });
        this.callback();
      }
    },
    ...mapActions(DOMAINS_MODEL.fleet.vehicleModels, [
      'deleteVehicleModel',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteVehicleModel"
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        :text="text"
        title="Are you sure you want to delete this model?"
      />
    </template>
    <template slot="footer">
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteModelStatus.LOADING"
        @click="removeVehicleModel"
      />
    </template>
  </GenericModalComponent>
</template>
