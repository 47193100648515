var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "VehicleModelsDetailView d-flex flex-column flex-fill",
        {
          "justify-content-center": _vm.isLoading,
        },
      ],
    },
    [
      _vm.isLoading
        ? _c("ui-loader", { attrs: { label: "Loading vehicle model..." } })
        : _vm.vehicleModel
        ? [
            _c("h2", { staticClass: "mb-2" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.vehicleModel.vehicleBrand.name) +
                  " " +
                  _vm._s(_vm.vehicleModel.name) +
                  " "
              ),
            ]),
            _c(
              "ui-card",
              {
                class: { "d-block mb-3": _vm.isElectricOrHybrid },
                attrs: { header: "General vehicle model information" },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-10" }, [
                    _c("div", { staticClass: "row mb-n2" }, [
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("ID"),
                        ]),
                        _c("div", { staticClass: "emobg-body-1 mt-1" }, [
                          _vm._v(" " + _vm._s(_vm.vehicleModel.id) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("Vehicle brand"),
                        ]),
                        _c("div", { staticClass: "emobg-body-1 mt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.vehicleModel.vehicleBrand.name) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("Model name"),
                        ]),
                        _c("div", { staticClass: "emobg-body-1 mt-1" }, [
                          _vm._v(" " + _vm._s(_vm.vehicleModel.name) + " "),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("Vehicle type"),
                        ]),
                        _c("div", { staticClass: "emobg-body-1 mt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.sentenceCase(_vm.vehicleModel.vehicleType)
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("Fuel type"),
                        ]),
                        _c("div", { staticClass: "emobg-body-1 mt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.sentenceCase(_vm.vehicleModel.fuelType)
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("Doors number"),
                        ]),
                        _c("div", { staticClass: "emobg-body-1 mt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.vehicleModel.doorsNumber ||
                                  _vm.FALLBACK_MESSAGE.dash
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("Year"),
                        ]),
                        _c("div", { staticClass: "emobg-body-1 mt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.vehicleModel.year ||
                                  _vm.FALLBACK_MESSAGE.dash
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("Battery danger level"),
                        ]),
                        _c("div", { staticClass: "emobg-body-1 mt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.vehicleModel.batteryDangerLevel ||
                                  _vm.FALLBACK_MESSAGE.dash
                              ) +
                              " volts "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("Battery critical level"),
                        ]),
                        _c("div", { staticClass: "emobg-body-1 mt-1" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.vehicleModel.batteryCriticalLevel ||
                                  _vm.FALLBACK_MESSAGE.dash
                              ) +
                              " volts "
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "col-sm-6 col-md-3 mb-4" }, [
                        _c("label", { staticClass: "emobg-label-1" }, [
                          _vm._v("Picture"),
                        ]),
                        _c("div", [
                          _c("img", {
                            staticClass: "mt-1",
                            staticStyle: {
                              width: "auto",
                              height: "100%",
                              "max-height": "60px",
                            },
                            attrs: {
                              src: _vm.vehicleModel.picture,
                              alt: "vehicleModelPicture",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col d-flex justify-content-end" },
                    [
                      _c("EditButton", {
                        staticClass: "mb-4",
                        on: {
                          click: function ($event) {
                            _vm.isVehicleModelFormOpened = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm.isElectricOrHybrid
              ? _c(
                  "ui-card",
                  {
                    attrs: {
                      header: "Electric vehicle model information",
                      description:
                        "Exclusive information for vehicle models with electric fuel type and plug in hybrid fuel type",
                    },
                  },
                  [
                    _c("div", { staticClass: "row mb-n2" }, [
                      _c("div", { staticClass: "col-10" }, [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-sm-6 col-md-3 mb-4" },
                              [
                                _c("label", { staticClass: "emobg-label-1" }, [
                                  _vm._v("Supported charge types"),
                                ]),
                                _vm.vehicleModel.chargeTypes
                                  ? _vm._l(
                                      _vm.vehicleModel.chargeTypes,
                                      function (chargeType, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "emobg-body-1 mt-1",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(chargeType.name) +
                                                " "
                                            ),
                                          ]
                                        )
                                      }
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.FALLBACK_MESSAGE.dash) +
                                          " "
                                      ),
                                    ]),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              { staticClass: "col-sm-6 col-md-3 mb-4" },
                              [
                                _c("label", { staticClass: "emobg-label-1" }, [
                                  _vm._v("Traction battery capacity"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "emobg-body-1 mt-1" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.vehicleModel
                                            .tractionBatteryCapacity ||
                                            _vm.FALLBACK_MESSAGE.dash
                                        ) +
                                        " kW "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm.vehicleModel.fuelType ===
                            _vm.FUEL_TYPES.electric
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-6 col-md-3 mb-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "emobg-label-1" },
                                        [
                                          _vm._v(
                                            "Traction battery danger level"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "emobg-body-1 mt-1" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.vehicleModel
                                                  .tractionBatteryDangerLevel ||
                                                  _vm.FALLBACK_MESSAGE.dash
                                              ) +
                                              "% "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "col-sm-6 col-md-3 mb-4" },
                                    [
                                      _c(
                                        "label",
                                        { staticClass: "emobg-label-1" },
                                        [
                                          _vm._v(
                                            "Traction battery critical level"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "emobg-body-1 mt-1" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.vehicleModel
                                                  .tractionBatteryCriticalLevel ||
                                                  _vm.FALLBACK_MESSAGE.dash
                                              ) +
                                              "% "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.isVehicleModelFormOpened
              ? _c("VehicleModelFormComponent", {
                  attrs: {
                    "vehicle-model-uuid": _vm.vehicleModelUuid,
                    callback: _vm.vehicleModelUpdated,
                  },
                  on: {
                    closeModal: function ($event) {
                      _vm.isVehicleModelFormOpened = false
                    },
                    "modal-closed": function ($event) {
                      _vm.isVehicleModelFormOpened = false
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }