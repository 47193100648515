<script>
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapGetters } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { camelCaseKeys, DELAY } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import { contentCells } from './config/contentCells';
import CategoryForm from '../components/CategoryForm';
import DeleteCategory from '../components/DeleteCategory';
import UpdateTariffsForm from '../components/UpdateTariffsForm';

export default {
  name: 'CategoriesListView',
  components: {
    UpdateTariffsForm,
    DeleteCategory,
    CategoryForm,
    MuiAlgoliaList,
  },
  data() {
    return {
      isLoading: false,
      isFormOpened: false,
      isTariffsFormOpened: false,
      isDeleteFormOpened: false,
      category: null,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;
    this.itemsActions = [
      {
        label: 'Duplicate tariffs',
        class: 'emobg-font-weight-semibold',
        method: category => {
          this.category = camelCaseKeys(category);
          this.isTariffsFormOpened = true;
        },
      },
      {
        label: 'Edit category',
        class: 'emobg-font-weight-semibold',
        method: category => {
          this.category = camelCaseKeys(category);
          this.isFormOpened = true;
        },
      },
      {
        label: 'Delete category',
        class: 'emobg-color-danger emobg-font-weight-semibold',
        method: category => {
          this.category = camelCaseKeys(category);
          this.isDeleteFormOpened = true;
        },
      },
    ];
  },
  methods: {
    refreshList() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.categoriesList, DELAY.extraLong, () => { this.isLoading = false; });
    },
    closeModal() {
      this.isFormOpened = false;
      this.isDeleteFormOpened = false;
      this.isTariffsFormOpened = false;
      this.category = null;
    },
  },
};
</script>

<template>
  <div
    class="CategoriesListView"
    data-test-id="categories_list-view"
  >
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1 class="flex-grow-1">
        Vehicle categories
      </h1>
      <ui-button
        data-test-id="open_form-button"
        @clickbutton="isFormOpened = true"
      >
        Add new category
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        label="Loading categories list..."
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="categoriesList"
        :item-actions="itemsActions"
        :export-columns="contentCells"
        :index="ALGOLIA_INDEXES.vehicleCategories"
        :table-config="contentCells"
        :filters="getOperatorFilter({ attribute: 'cs_operator_uuid', searchUsing: 'uuid' })"
        data-test-id="list"
        is-export-enabled
      />
    </div>
    <CategoryForm
      v-if="isFormOpened"
      :category="category"
      :on-success="refreshList"
      @closeModal="closeModal"
    />
    <DeleteCategory
      v-if="isDeleteFormOpened"
      :category="category"
      :on-success="refreshList"
      @closeModal="closeModal"
    />
    <UpdateTariffsForm
      v-if="isTariffsFormOpened"
      :category="category"
      :on-success="refreshList"
      @closeModal="closeModal"
    />
  </div>
</template>
