<script>
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import {
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  ImageTemplate,
  StoreNotificationComponent,
} from '@/components';
import { scopes } from '../store/VehicleBrandsModule';

export default {
  name: 'BrandForm',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiValidationWrapper,
    StoreNotificationComponent,
    ContentCellComponent,
    ImageTemplate,
  },
  props: {
    brand: {
      type: Object,
      default: null,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        name: '',
        logo: '',
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.vehicleBrands, {
      brandStatus: state => state.vehicleBrand.STATUS,
    }),
    isEditing() {
      return !!this.brand;
    },
    isPreviewing() {
      return this.brand && this.isPreview;
    },
    hasSameValues() {
      return this.isEditing ? isEqual(this.inputs, this.originalInputs) : false;
    },
    modalTitle() {
      const editPreviewTitle = this.isPreview ? 'Preview brand' : 'Edit brand';
      return this.isEditing
        ? editPreviewTitle
        : 'Add new brand';
    },
  },
  created() {
    this.scopes = scopes;
    this.DOMAINS_MODEL = DOMAINS_MODEL;

    if (this.isEditing) {
      this.inputs.name = this.brand.name;
      this.inputs.logo = this.brand.logo;
      this.originalInputs = cloneDeep(this.inputs);
    }
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.vehicleBrands, ['putVehicleBrand', 'postVehicleBrand']),
    async brandRequest() {
      const method = this.isEditing ? this.putVehicleBrand : this.postVehicleBrand;
      const data = this.isEditing ? { brandId: this.brand.id, data: this.inputs } : this.inputs;
      const action = this.isEditing ? 'edited' : 'added';

      await method(data);

      if (!this.brandStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: `Vehicle brand successfully <span class="emobg-font-weight-semibold">${action}</span>`,
          textAction: '',
        });

        this.onSuccess();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :title="modalTitle"
    class="BrandForm"
    data-test-id="brand-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.vehicleBrands"
        :scope="scopes.vehicleBrand"
        :is-editing="isEditing"
        element="vehicle brand"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <div class="col-12 mb-3">
            <ContentCellComponent
              v-if="isPreviewing"
              :value="brand.name"
              label="Name"
            />
            <MuiInputText
              v-else
              v-model="inputs.name"
              v-validate="{
                isRequired: true,
              }"
              label="Name*"
              placeholder="Enter brand name"
              data-test-id="name-input"
              name="name"
              class="w-100"
            />
          </div>

          <div class="col-12">
            <ContentCellComponent
              v-if="isPreviewing"
              label="Logo source"
              class="mb-3"
            >
              <a
                :href="brand.logo"
                class="emobg-font-weight-semibold emobg-color-primary text-decoration-none"
                target="_blank"
              >
                {{ brand.logo }}
              </a>

              <ImageTemplate
                :url="brand.logo"
                :height="200"
                class="text-center mt-3"
              />
            </ContentCellComponent>

            <template v-else>
              <MuiInputText
                v-model="inputs.logo"
                v-validate="{
                  isRequired: true,
                }"
                label="Logo source*"
                placeholder="Enter image url"
                data-test-id="logo-input"
                name="logo"
                class="w-100"
              />
              <ImageTemplate
                :url="inputs.logo"
                :height="200"
                class="text-center mt-3"
              />
            </template>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template
      v-if="!isPreview"
      #footer
    >
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="() => $emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="brandStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="brandRequest"
        >
          {{ isEditing ? 'Save' : 'Add' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
