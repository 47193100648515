import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { ImageTemplate } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export const contentCells = [
  {
    attributeName: 'id',
    title: 'ID',
    displayPriority: 1,
    minWidth: 50,
  },
  {
    attributeName: 'name',
    title: 'Name',
    displayPriority: 1,
    minWidth: 150,
  },
  {
    attributeName: 'internal_name',
    title: 'Internal name',
    displayPriority: 1,
    minWidth: 150,
  },
  {
    attributeName: 'order',
    title: 'Display order',
    displayPriority: 1,
    minWidth: 50,
  },
  {
    attributeName: 'active',
    title: 'Status',
    type: RECORD_TYPES.component,
    displayPriority: 1,
    minWidth: 150,
    component: BadgeComponent,
    props: result => ({
      color: result.active ? COLORS.success : GRAYSCALE.ground,
      text: result.active ? 'Active' : 'Inactive',
    }),
  },
  {
    attributeName: 'image',
    title: 'Picture',
    displayPriority: 1,
    minWidth: 200,
    type: RECORD_TYPES.component,
    component: ImageTemplate,
    props: ({ image }) => ({
      url: image,
    }),
  },
];
