<script>
import {
  PageTabs,
  PageView,
} from '@/components';
import FleetRouterMap from '../router/FleetRouterMap';

export default {
  name: 'VehicleSettingsView',
  components: {
    PageTabs,
    PageView,
  },
  created() {
    this.tabs = [
      {
        label: {
          title: 'Brands',
        },
        url: FleetRouterMap.vehicleSettings.brands,
      },
      {
        label: {
          title: 'Models',
        },
        url: FleetRouterMap.vehicleSettings.models.index,
      },
      {
        label: {
          title: 'Equipment',
        },
        url: FleetRouterMap.vehicleSettings.equipment,
      },
      {
        label: {
          title: 'Categories',
        },
        url: FleetRouterMap.vehicleSettings.categories,
      },
    ];
  },
};
</script>

<template>
  <div class="VehicleSettingsView d-flex flex-column flex-fill">
    <PageView class="emobg-background-color-white py-4">
      <h2>
        Vehicle settings
      </h2>
    </PageView>
    <PageTabs
      :tabs="tabs"
      has-overview
    />
    <PageView is-tab-content>
      <Transition name="page">
        <RouterView />
      </Transition>
    </PageView>
  </div>
</template>
