var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "UpdateTariffs",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.small,
          title: "Duplicate tariffs",
          "data-test-id": "tariffs-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.vehicleCategories,
                    scope: _vm.scopes.tariffs,
                    action:
                      "<span class='emobg-font-weight-semibold'>duplicate</span> tariffs",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
                  [
                    _c("MuiAlgoliaSelect", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            isRequired: true,
                          },
                          expression:
                            "{\n          isRequired: true,\n        }",
                        },
                      ],
                      staticClass: "w-100",
                      attrs: {
                        title: (category) => category.internal_name,
                        index: _vm.categoriesIndex,
                        filters: `cs_operator_fk: ${_vm.category.csOperatorFk}`,
                        label:
                          "Select the category of tariffs you want to duplicate*",
                        placeholder: "Select category",
                        "path-value": "id",
                        name: "category",
                        "data-test-id": "category-select",
                      },
                      model: {
                        value: _vm.inputs.baseVehicleCategoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "baseVehicleCategoryId", $$v)
                        },
                        expression: "inputs.baseVehicleCategoryId",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: !_vm.isFormValid,
                          loading: _vm.tariffsStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.updateTariffs },
                      },
                      [_vm._v(" Duplicate ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }