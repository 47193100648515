var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "VehicleModelFormComponent",
        attrs: { title: _vm.modalTitle, header: { isClosable: true } },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.fleet.vehicleModels,
              scope: _vm.VEHICLE_MODELS_SCOPES.newModel,
              "is-editing": !!_vm.vehicleModelUuid,
              element: "model",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (isValid) => (_vm.isFormValid = isValid) } },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "pr-2" },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-font-weight-semibold mb-1",
                          },
                          [_vm._v(" Vehicle brand* ")]
                        ),
                        _vm.isInitialized
                          ? _c("MuiAlgoliaSelect", {
                              staticClass: "w-100 mb-4",
                              attrs: {
                                index: _vm.ALGOLIA_INDEXES.vehicleBrands,
                                title: (brand) => brand.name,
                                name: "brandUuid",
                                "no-cache": "",
                                "path-value": "uuid",
                                placeholder: "Select a vehicle brand",
                              },
                              model: {
                                value: _vm.inputs.vehicleBrandUuid,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "vehicleBrandUuid", $$v)
                                },
                                expression: "inputs.vehicleBrandUuid",
                              },
                            })
                          : _c("ui-skeleton", { staticClass: "mb-4" }),
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-font-weight-semibold mb-1",
                          },
                          [_vm._v(" Model name* ")]
                        ),
                        _vm.isInitialized
                          ? _c("MuiInputText", {
                              staticClass: "mb-4 w-100",
                              attrs: {
                                placeholder: "Enter a model name",
                                name: "name",
                              },
                              model: {
                                value: _vm.inputs.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inputs, "name", $$v)
                                },
                                expression: "inputs.name",
                              },
                            })
                          : _c("ui-skeleton", { staticClass: "mb-4" }),
                        _vm.isInitialized
                          ? _c("ui-select", {
                              staticClass: "w-100 d-block mb-4",
                              attrs: {
                                value: _vm.inputs.vehicleType,
                                color: _vm.GRAYSCALE.groundLighter,
                                label: "Vehicle type*",
                                placeholder: "Select a vehicle type",
                                name: "vehicleType",
                              },
                              domProps: {
                                options: _vm.map(
                                  _vm.VEHICLE_TYPES,
                                  (value) => ({
                                    label: _vm.sentenceCase(value),
                                    value,
                                  })
                                ),
                              },
                              on: {
                                selectoption: ({ detail }) =>
                                  (_vm.inputs.vehicleType = detail),
                              },
                            })
                          : _c("ui-skeleton", { staticClass: "mb-4" }),
                        _vm.isInitialized
                          ? _c("ui-select", {
                              staticClass: "d-block w-100 mb-4",
                              attrs: {
                                value: _vm.inputs.fuelType,
                                disabled: !!_vm.vehicleModelUuid,
                                color: _vm.GRAYSCALE.groundLighter,
                                label: "Fuel type*",
                                placeholder: "Select a fuel type",
                                name: "fuelType",
                              },
                              domProps: {
                                options: _vm.map(_vm.FUEL_TYPES, (value) => ({
                                  label: _vm.sentenceCase(value),
                                  value,
                                })),
                              },
                              on: {
                                selectoption: ({ detail }) =>
                                  (_vm.inputs.fuelType = detail),
                              },
                            })
                          : _c("ui-skeleton", { staticClass: "mb-4" }),
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-font-weight-semibold mb-1",
                          },
                          [_vm._v(" Doors number* ")]
                        ),
                        _vm.isInitialized
                          ? _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isPattern:
                                          _vm.PATTERN_INPUT_VALIDATIONS
                                            .wholeNumber,
                                      },
                                      expression:
                                        "{\n                  isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n                }",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  attrs: {
                                    value: _vm.inputs.doorsNumber,
                                    placeholder:
                                      "Enter a number value for doors",
                                    name: "doors",
                                  },
                                  model: {
                                    value: _vm.inputs.doorsNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inputs,
                                        "doorsNumber",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "inputs.doorsNumber",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("ui-skeleton", { staticClass: "mb-4" }),
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-font-weight-semibold mb-1",
                          },
                          [_vm._v(" Year* ")]
                        ),
                        _vm.isInitialized
                          ? _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isPattern:
                                          _vm.PATTERN_INPUT_VALIDATIONS
                                            .wholeNumber,
                                      },
                                      expression:
                                        "{\n                  isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n                }",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder:
                                      "Enter a number value for year",
                                    name: "year",
                                  },
                                  model: {
                                    value: _vm.inputs.year,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputs, "year", _vm._n($$v))
                                    },
                                    expression: "inputs.year",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c("ui-skeleton", { staticClass: "mb-4" }),
                        _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-font-weight-semibold mb-1",
                          },
                          [_vm._v(" Vehicle picture* ")]
                        ),
                        _vm.isInitialized
                          ? _c("DragFileComponent", {
                              attrs: {
                                "existing-files": _vm.currentImages,
                                "show-preview": "",
                              },
                              on: {
                                change: _vm.onFilesChange,
                                existingFileRemoved: (files) =>
                                  (_vm.currentImages = files),
                              },
                              model: {
                                value: _vm.files,
                                callback: function ($$v) {
                                  _vm.files = $$v
                                },
                                expression: "files",
                              },
                            })
                          : _c("ui-skeleton", { staticClass: "mb-4" }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "col-6" }, [
                  _c(
                    "div",
                    { staticClass: "pl-2" },
                    [
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block emobg-font-weight-semibold mb-1",
                        },
                        [_vm._v(" Battery danger level* ")]
                      ),
                      _vm.isInitialized
                        ? _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isPattern:
                                        _vm.PATTERN_INPUT_VALIDATIONS
                                          .decimalNumber,
                                    },
                                    expression:
                                      "{\n                  isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n                }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: "Enter a number value for volts",
                                  name: "batteryDangerLevel",
                                },
                                on: {
                                  blur: (value) =>
                                    (_vm.inputs.batteryDangerLevel =
                                      _vm.toNumber(value)),
                                },
                                model: {
                                  value: _vm.inputs.batteryDangerLevel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inputs,
                                      "batteryDangerLevel",
                                      $$v
                                    )
                                  },
                                  expression: "inputs.batteryDangerLevel",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("ui-skeleton", { staticClass: "mb-4" }),
                      _c(
                        "label",
                        {
                          staticClass:
                            "d-block emobg-font-weight-semibold mb-1",
                        },
                        [_vm._v(" Battery critical level* ")]
                      ),
                      _vm.isInitialized
                        ? _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isPattern:
                                        _vm.PATTERN_INPUT_VALIDATIONS
                                          .decimalNumber,
                                    },
                                    expression:
                                      "{\n                  isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n                }",
                                  },
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: "Enter a number value for volts",
                                  name: "batteryCriticalLevel",
                                },
                                on: {
                                  blur: (value) =>
                                    (_vm.inputs.batteryCriticalLevel =
                                      _vm.toNumber(value)),
                                },
                                model: {
                                  value: _vm.inputs.batteryCriticalLevel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.inputs,
                                      "batteryCriticalLevel",
                                      $$v
                                    )
                                  },
                                  expression: "inputs.batteryCriticalLevel",
                                },
                              }),
                            ],
                            1
                          )
                        : _c("ui-skeleton", { staticClass: "mb-4" }),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isElectricOrHybrid,
                              expression: "isElectricOrHybrid",
                            },
                          ],
                          staticClass: "mb-4",
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-weight-semibold mb-1",
                            },
                            [_vm._v(" Supported charge types* ")]
                          ),
                          _c("AlgoliaOptionsComponent", {
                            attrs: {
                              label: (option) => option.name,
                              index: _vm.ALGOLIA_INDEXES.chargeTypes,
                              "path-value": "uuid",
                            },
                            model: {
                              value: _vm.inputs.chargeTypes,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "chargeTypes", $$v)
                              },
                              expression: "inputs.chargeTypes",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.isElectricOrHybrid
                        ? [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-semibold mb-1",
                              },
                              [_vm._v(" Traction battery capacity* ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isPattern:
                                          _vm.PATTERN_INPUT_VALIDATIONS
                                            .wholeNumber,
                                      },
                                      expression:
                                        "{\n                    isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n                  }",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder: "Enter a number value for kW",
                                    name: "tractionBatteryCapacity",
                                  },
                                  model: {
                                    value: _vm.inputs.tractionBatteryCapacity,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inputs,
                                        "tractionBatteryCapacity",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression:
                                      "inputs.tractionBatteryCapacity",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.inputs.fuelType === _vm.FUEL_TYPES.electric
                        ? [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-semibold mb-1",
                              },
                              [_vm._v(" Traction battery danger level* ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isPattern:
                                          _vm.PATTERN_INPUT_VALIDATIONS
                                            .wholeNumber,
                                      },
                                      expression:
                                        "{\n                    isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n                  }",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder:
                                      "Enter a number value for percentage",
                                    name: "tractionBatteryDangerLevel",
                                  },
                                  model: {
                                    value:
                                      _vm.inputs.tractionBatteryDangerLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inputs,
                                        "tractionBatteryDangerLevel",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression:
                                      "inputs.tractionBatteryDangerLevel",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm.inputs.fuelType === _vm.FUEL_TYPES.electric
                        ? [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "d-block emobg-font-weight-semibold mb-1",
                              },
                              [_vm._v(" Traction battery critical level* ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isPattern:
                                          _vm.PATTERN_INPUT_VALIDATIONS
                                            .wholeNumber,
                                      },
                                      expression:
                                        "{\n                    isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n                  }",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  attrs: {
                                    placeholder:
                                      "Enter a number value for percentage",
                                    name: "tractionBatteryCriticalLevel",
                                  },
                                  model: {
                                    value:
                                      _vm.inputs.tractionBatteryCriticalLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inputs,
                                        "tractionBatteryCriticalLevel",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression:
                                      "inputs.tractionBatteryCriticalLevel",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c("CancelButton", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "wmin-initial",
                    attrs: {
                      disabled:
                        !_vm.areRequiredFieldsFilled || _vm.hasSameValues,
                      loading: _vm.newModelStatus.LOADING,
                    },
                    on: { clickbutton: _vm.request },
                  },
                  [_vm._v(" Save ")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }