import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { ALL_COLORS } from '@emobg/vue-base';
import upperFirst from 'lodash/upperFirst';

export const EQUIPMENT_SCHEMA = [
  {
    header: 'Area',
    template: equipment => upperFirst(equipment.area),
  },
  {
    header: 'Internal name',
    template: 'internalName',
  },
  {
    header: 'Name',
    template: 'name',
  },
  {
    header: 'Status',
    component: BadgeComponent,
    props: equipment => ({
      text: equipment.active ? 'Active' : 'Inactive',
      color: equipment.active ? ALL_COLORS.success : ALL_COLORS.ground,
    }),
  },
];

export const EQUIPMENT_AREAS = [
  'interior',
  'exterior',
  'general',
];
