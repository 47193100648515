import { RECORD_TYPES } from '@emobg/motion-ui';
import { ImageTemplate } from '@/components';

export const contentCells = [
  {
    attributeName: 'id',
    title: 'ID',
    displayPriority: 1,
    minWidth: 50,
  },
  {
    attributeName: 'name',
    title: 'Name',
    displayPriority: 1,
    minWidth: 150,
  },
  {
    attributeName: 'logo',
    title: 'Logo preview',
    displayPriority: 1,
    minWidth: 200,
    type: RECORD_TYPES.component,
    component: ImageTemplate,
    props: ({ logo, uuid }) => ({
      url: logo,
      key: uuid,
    }),
  },
  {
    attributeName: 'logo',
    title: 'Logo source',
    displayPriority: 1,
    type: RECORD_TYPES.template,
    minWidth: 150,
    template: ({ logo }) => `<a class="d-block ellipsis emobg-link-primary emobg-body-2" href="${logo}" target="_blank">${logo}</a>`,
  },
];

export const exportColumns = [
  {
    attributeName: 'id',
    title: 'ID',
  },
  {
    attributeName: 'name',
    title: 'Name',
  },
  {
    attributeName: 'logo',
    title: 'Logo source',
  },
];
