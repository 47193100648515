<script>
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1/dist';
import { scopes } from '@domains/Fleet/VehicleSettings/Categories/store/VehicleCategoriesModule';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  CancelButton,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';

export default {
  name: 'UpdateTariffsForm',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      isFormValid: false,
      inputs: {
        baseVehicleCategoryId: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.vehicleCategories, {
      tariffsStatus: state => state.tariffs.STATUS,
    }),
  },
  created() {
    this.scopes = scopes;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.categoriesIndex = ALGOLIA_INDEXES.vehicleCategories;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.vehicleCategories, ['postUpdateTariffs']),
    async updateTariffs() {
      await this.postUpdateTariffs({ categoryId: this.category.id, data: this.inputs });

      if (!this.tariffsStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Vehicle category tariffs successfully <span class="emobg-font-weight-semibold">duplicated</span>',
          textAction: '',
        });

        this.onSuccess();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.small"
    title="Duplicate tariffs"
    class="UpdateTariffs"
    data-test-id="tariffs-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.vehicleCategories"
        :scope="scopes.tariffs"
        action="<span class='emobg-font-weight-semibold'>duplicate</span> tariffs"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <MuiAlgoliaSelect
          v-model="inputs.baseVehicleCategoryId"
          v-validate="{
            isRequired: true,
          }"
          :title="category => category.internal_name"
          :index="categoriesIndex"
          :filters="`cs_operator_fk: ${category.csOperatorFk}`"
          label="Select the category of tariffs you want to duplicate*"
          placeholder="Select category"
          class="w-100"
          path-value="id"
          name="category"
          data-test-id="category-select"
        />
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="$emit('closeModal')"
        />

        <ui-button
          :disabled="!isFormValid"
          :loading="tariffsStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="updateTariffs"
        >
          Duplicate
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
