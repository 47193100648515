var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteVehicleBrand",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: { title: "Delete this brand?", text: _vm.brand.name },
                }),
                _c("ImageTemplate", {
                  staticClass: "text-center mt-3",
                  attrs: { url: _vm.brand.logo, height: 200 },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c("DeleteButton", {
                  attrs: { loading: _vm.deleteBrandStatus.LOADING },
                  on: { click: _vm.removeVehicleBrand },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }