import { sentenceCase } from '@emobg/web-utils';

export { contentCells } from './contentCells';

export const attributesToRetrieve = [
  'id',
  'uuid',
  'vehicle_brand',
  'name',
  'fuel_type',
  'doors_number',
  'picture',
];

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Vehicle brand',
      attributeName: 'vehicle_brand',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Model name',
      attributeName: 'name',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Fuel type',
      attributeName: 'fuel_type',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Doors number',
      attributeName: 'doors_number',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Year',
      attributeName: 'years',
    },
  },
];
