<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { DELAY } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import { contentCells, exportColumns } from './config/contentCells';
import BrandForm from '../components/BrandForm';
import DeleteBrand from '../components/DeleteBrand';

export default {
  name: 'BrandsListView',
  components: {
    BrandForm,
    DeleteBrand,
    MuiAlgoliaList,
  },
  data() {
    return {
      isLoading: false,
      isFormOpened: false,
      isDeleteFormOpened: false,
      brand: null,
      isPreviewMode: false,
    };
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells;
    this.exportColumns = exportColumns;
    this.itemsActions = [
      {
        label: 'Preview brand',
        class: 'emobg-font-weight-semibold',
        method: brand => {
          this.brand = brand;
          this.isPreviewMode = true;
          this.isFormOpened = true;
        },
      },
      {
        label: 'Edit brand',
        class: 'emobg-font-weight-semibold',
        method: brand => {
          this.brand = brand;
          this.isFormOpened = true;
        },
      },
      {
        label: 'Delete brand',
        class: 'emobg-color-danger emobg-font-weight-semibold',
        method: brand => {
          this.brand = brand;
          this.isDeleteFormOpened = true;
        },
      },
    ];
  },
  methods: {
    refreshList() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.brandsList, DELAY.extraLong, () => { this.isLoading = false; });
    },
    closeModal() {
      this.isFormOpened = false;
      this.isPreviewMode = false;
      this.isDeleteFormOpened = false;
      this.brand = null;
    },
  },
};
</script>

<template>
  <div
    class="BrandsListView"
    data-test-id="brands_list-view"
  >
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h1 class="flex-grow-1">
        Vehicle brands
      </h1>
      <ui-button
        data-test-id="open_form-button"
        @clickbutton="isFormOpened = true"
      >
        Add new brand
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        label="Loading brands list..."
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="brandsList"
        :item-actions="itemsActions"
        :export-columns="exportColumns"
        :index="ALGOLIA_INDEXES.vehicleBrands"
        :table-config="contentCells"
        data-test-id="list"
        is-export-enabled
      />
    </div>
    <BrandForm
      v-if="isFormOpened"
      :is-preview="isPreviewMode"
      :brand="brand"
      :on-success="refreshList"
      @closeModal="closeModal"
    />

    <DeleteBrand
      v-if="isDeleteFormOpened"
      :brand="brand"
      :on-success="refreshList"
      @closeModal="closeModal"
    />
  </div>
</template>
