var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "BrandForm",
        attrs: {
          header: { isClosable: true },
          title: _vm.modalTitle,
          "data-test-id": "brand-form",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "alerts",
              fn: function () {
                return [
                  _c("StoreNotificationComponent", {
                    attrs: {
                      "store-domain": _vm.DOMAINS_MODEL.fleet.vehicleBrands,
                      scope: _vm.scopes.vehicleBrand,
                      "is-editing": _vm.isEditing,
                      element: "vehicle brand",
                      "data-test-id": "notification",
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "MuiValidationWrapper",
                    {
                      on: { areAllValid: (valid) => (_vm.isFormValid = valid) },
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12 mb-3" },
                          [
                            _vm.isPreviewing
                              ? _c("ContentCellComponent", {
                                  attrs: {
                                    value: _vm.brand.name,
                                    label: "Name",
                                  },
                                })
                              : _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isRequired: true,
                                      },
                                      expression:
                                        "{\n              isRequired: true,\n            }",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  attrs: {
                                    label: "Name*",
                                    placeholder: "Enter brand name",
                                    "data-test-id": "name-input",
                                    name: "name",
                                  },
                                  model: {
                                    value: _vm.inputs.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputs, "name", $$v)
                                    },
                                    expression: "inputs.name",
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _vm.isPreviewing
                              ? _c(
                                  "ContentCellComponent",
                                  {
                                    staticClass: "mb-3",
                                    attrs: { label: "Logo source" },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "emobg-font-weight-semibold emobg-color-primary text-decoration-none",
                                        attrs: {
                                          href: _vm.brand.logo,
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.brand.logo) + " "
                                        ),
                                      ]
                                    ),
                                    _c("ImageTemplate", {
                                      staticClass: "text-center mt-3",
                                      attrs: {
                                        url: _vm.brand.logo,
                                        height: 200,
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : [
                                  _c("MuiInputText", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                        },
                                        expression:
                                          "{\n                isRequired: true,\n              }",
                                      },
                                    ],
                                    staticClass: "w-100",
                                    attrs: {
                                      label: "Logo source*",
                                      placeholder: "Enter image url",
                                      "data-test-id": "logo-input",
                                      name: "logo",
                                    },
                                    model: {
                                      value: _vm.inputs.logo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputs, "logo", $$v)
                                      },
                                      expression: "inputs.logo",
                                    },
                                  }),
                                  _c("ImageTemplate", {
                                    staticClass: "text-center mt-3",
                                    attrs: {
                                      url: _vm.inputs.logo,
                                      height: 200,
                                    },
                                  }),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            !_vm.isPreview
              ? {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-content-center justify-content-sm-end align-items-center",
                        },
                        [
                          _c("CancelButton", {
                            attrs: { "data-test-id": "close_modal-button" },
                            on: { click: () => _vm.$emit("closeModal") },
                          }),
                          _c(
                            "ui-button",
                            {
                              staticClass: "wmin-initial",
                              attrs: {
                                disabled: _vm.hasSameValues || !_vm.isFormValid,
                                loading: _vm.brandStatus.LOADING,
                                "data-test-id": "save-button",
                              },
                              on: { clickbutton: _vm.brandRequest },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.isEditing ? "Save" : "Add") +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }