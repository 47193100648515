var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "VehicleSettingsView d-flex flex-column flex-fill" },
    [
      _c("PageView", { staticClass: "emobg-background-color-white py-4" }, [
        _c("h2", [_vm._v(" Vehicle settings ")]),
      ]),
      _c("PageTabs", { attrs: { tabs: _vm.tabs, "has-overview": "" } }),
      _c(
        "PageView",
        { attrs: { "is-tab-content": "" } },
        [_c("Transition", { attrs: { name: "page" } }, [_c("RouterView")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }