var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CategoryForm",
        attrs: {
          header: { isClosable: true },
          size: _vm.SIZES.large,
          title: _vm.modalTitle,
          "data-test-id": "category-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.fleet.vehicleCategories,
                    scope: _vm.scopes.vehicleCategory,
                    "is-editing": _vm.isEditing,
                    "use-message": _vm.useStoreMessage,
                    element: "vehicle category",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm.isFormReady
                  ? _c(
                      "MuiValidationWrapper",
                      {
                        on: {
                          areAllValid: (valid) => (_vm.isFormValid = valid),
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-6 mb-3" },
                              [
                                _c("ui-toggle", {
                                  attrs: {
                                    value: _vm.inputs.active,
                                    text: _vm.inputs.active
                                      ? "Active"
                                      : "Inactive",
                                    label: "Status",
                                    "data-test-id": "active-toggle",
                                    name: "active",
                                  },
                                  on: {
                                    changevalue: ({ detail }) =>
                                      (_vm.inputs.active = detail),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-6 mb-3" },
                              [
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isRequired: true,
                                      },
                                      expression:
                                        "{\n              isRequired: true,\n            }",
                                    },
                                  ],
                                  staticClass: "w-100 mb-3",
                                  attrs: {
                                    disabled: _vm.isEditing,
                                    label: "Internal name*",
                                    name: "internalName",
                                    placeholder: "Enter internal name",
                                    "data-test-id": "internal_name-input",
                                  },
                                  model: {
                                    value: _vm.inputs.internalName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputs, "internalName", $$v)
                                    },
                                    expression: "inputs.internalName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-12 mb-3" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "d-block emobg-font-weight-semibold mb-1",
                                  },
                                  [_vm._v(" Category picture ")]
                                ),
                                _c("DragFileComponent", {
                                  attrs: {
                                    "existing-files": _vm.currentImages,
                                    "draggable-height": 200,
                                    "show-preview": "",
                                  },
                                  on: {
                                    change: _vm.onFilesChange,
                                    existingFileRemoved: (files) =>
                                      (_vm.currentImages = files),
                                  },
                                  model: {
                                    value: _vm.files,
                                    callback: function ($$v) {
                                      _vm.files = $$v
                                    },
                                    expression: "files",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-6 mb-3" },
                              [
                                _c("MuiAlgoliaSelect", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isRequired: true,
                                      },
                                      expression:
                                        "{\n              isRequired: true,\n            }",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: {
                                    title: (operator) => operator.name,
                                    index: _vm.csOperatorsIndex,
                                    filters: `id:${_vm.activeOperatorId} OR parent_cs_operator_id:${_vm.activeOperatorId}`,
                                    label: "Operator*",
                                    placeholder: "Select operator",
                                    "path-value": "id",
                                    name: "operator",
                                    "data-test-id": "operator-select",
                                  },
                                  on: {
                                    selected: ({ uuid }) =>
                                      (_vm.selectedOperatorUuid = uuid),
                                  },
                                  model: {
                                    value: _vm.inputs.csOperatorFk,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputs, "csOperatorFk", $$v)
                                    },
                                    expression: "inputs.csOperatorFk",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-6 mb-3" },
                              [
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isRequired: true,
                                      },
                                      expression:
                                        "{\n              isRequired: true,\n            }",
                                    },
                                  ],
                                  staticClass: "w-100",
                                  attrs: {
                                    type: "number",
                                    label: "Display order (App/Webapp)*",
                                    name: "order",
                                    placeholder:
                                      "Enter a number value for display order",
                                    "data-test-id": "order-input",
                                  },
                                  model: {
                                    value: _vm.inputs.order,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputs, "order", $$v)
                                    },
                                    expression: "inputs.order",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _c("TranslatableFieldComponent", {
                                  staticClass: "row",
                                  attrs: {
                                    language: _vm.language,
                                    placeholder: `Enter name (${_vm.language})`,
                                    "is-input-text": "",
                                    prefix: "name",
                                    label: "Name",
                                    "data-test-id": "name-input",
                                  },
                                  on: {
                                    "update:language": (selectedLanguage) =>
                                      (_vm.language = selectedLanguage),
                                  },
                                  model: {
                                    value: _vm.inputs.nameTranslations,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inputs,
                                        "nameTranslations",
                                        $$v
                                      )
                                    },
                                    expression: "inputs.nameTranslations",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.operatorConfigStatus.IDLE ||
                            _vm.operatorConfigStatus.LOADING
                              ? _c("ui-skeleton", {
                                  staticClass: "col-12 mt-2",
                                  attrs: { height: "48" },
                                })
                              : _vm.promoteVehiclesInTheSearchResult
                              ? _c("div", { staticClass: "col-12 mt-2" }, [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "emobg-font-weight-semibold emobg-font-medium mb-3",
                                    },
                                    [_vm._v(" Vehicle promotion rules ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3",
                                    },
                                    [
                                      _c("ui-toggle", {
                                        attrs: {
                                          value: _vm.canPromoteCategory,
                                          text: "Promote vehicle category in the search results",
                                          "data-test-id": "promote-toggle",
                                          name: "promoteCategory",
                                        },
                                        on: {
                                          changevalue:
                                            _vm.onChangePromoteToggle,
                                        },
                                      }),
                                      _vm.canPromoteCategory
                                        ? _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center emobg-font-weight-semibold mb-1 ml-1",
                                                },
                                                [
                                                  _vm._v(" Maximum mileage* "),
                                                  _c(
                                                    "ui-tooltip",
                                                    {
                                                      staticClass:
                                                        "ml-1 text-center",
                                                      attrs: {
                                                        tooltip:
                                                          "Below the maximum mileage, this vehicle category will appear first in the search results. Above that, it will not be suggested to users",
                                                      },
                                                    },
                                                    [
                                                      _c("ui-icon", {
                                                        staticClass:
                                                          "emobg-color-ink-light emobg-color-ink-hover",
                                                        attrs: {
                                                          icon: _vm.ICONS
                                                            .infoFull,
                                                          size: _vm.ICONS_SIZES
                                                            .medium,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("MuiInputText", {
                                                directives: [
                                                  {
                                                    name: "validate",
                                                    rawName: "v-validate",
                                                    value: {
                                                      isRequired: true,
                                                      isValidMileage:
                                                        _vm.isValidMileage,
                                                    },
                                                    expression:
                                                      "{\n                  isRequired: true,\n                  isValidMileage,\n                }",
                                                  },
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  name: "mileage",
                                                  placeholder:
                                                    "Enter a number value for kms",
                                                  "data-test-id":
                                                    "maximumMileage-input",
                                                },
                                                model: {
                                                  value:
                                                    _vm.inputs.maximumMileage,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.inputs,
                                                      "maximumMileage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "inputs.maximumMileage",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      attrs: { "data-test-id": "close_modal-button" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    _c(
                      "ui-button",
                      {
                        staticClass: "wmin-initial",
                        attrs: {
                          disabled: _vm.hasSameValues || !_vm.isFormValid,
                          loading: _vm.categoryStatus.LOADING,
                          "data-test-id": "save-button",
                        },
                        on: { clickbutton: _vm.categoryRequest },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.isEditing ? "Save" : "Add") + " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }