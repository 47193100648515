<script>
import result from 'lodash/result';
import { camelCaseKeys, DELAY } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import VehicleModelFromComponent from '../components/VehicleModelFormComponent';
import DeleteVehicleModel from '../components/DeleteVehicleModel';
import {
  attributesToRetrieve,
  contentCells,
  facets,
} from './config';

export default {
  name: 'VehicleModelsListView',
  components: {
    DeleteVehicleModel,
    MuiAlgoliaList,
    VehicleModelFromComponent,
  },
  data() {
    return {
      isLoading: false,
      uuidToEdit: null,
      isModalVisible: false,
      vehicleModel: null,
      isDeleteModalVisible: false,
    };
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    const element = 'model';
    this.attributesToRetrieve = attributesToRetrieve;
    this.contentCells = contentCells();
    this.exportColumns = this.contentCells;
    this.facets = facets;
    this.itemsActions = [
      {
        label: this.$t('Common.Actions.edit_element', { element }),
        method: ({ uuid }) => {
          this.uuidToEdit = uuid;
          this.isModalVisible = true;
        },
      },
      {
        label: `Delete ${element}`,
        type: 'danger',
        class: 'emobg-color-danger',
        method: vehicleModel => {
          this.vehicleModel = camelCaseKeys(vehicleModel);
          this.isDeleteModalVisible = true;
        },
      },
    ];
  },
  methods: {
    closeModal() {
      this.isModalVisible = false;
      this.uuidToEdit = null;
      this.vehicleModel = null;
      this.isDeleteModalVisible = false;
    },
    onFormSuccess() {
      this.closeModal();
      this.isLoading = true;
      // TODO: Change by util method - https://europcarmobility.atlassian.net/browse/CBF-887
      setTimeout(() => {
        result(this.$refs.models.mainSearchStore.clearCache());
        result(this.$refs.models.mainSearchStore.refresh());
        this.isLoading = false;
      }, DELAY.extraLong);
    },
  },
};
</script>

<template>
  <div class="VehicleModelsListView">
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Vehicle models
      </h1>
      <ui-button
        data-test-id="create_vehicle_model-button"
        @clickbutton="isModalVisible = true"
      >
        Create new vehicle model
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
      />
      <MuiAlgoliaList
        ref="models"
        :item-actions="itemsActions"
        :export-columns="exportColumns"
        :facets="facets"
        :labels="{
          searchTable: 'Search by brand, model name or fuel type',
        }"
        :table-config="contentCells"
        :query-parameters="{ attributesToRetrieve }"
        :index="ALGOLIA_INDEXES.vehicleModels"
        is-export-enabled
      />
    </div>

    <VehicleModelFromComponent
      v-if="isModalVisible"
      :vehicle-model-uuid="uuidToEdit"
      :callback="onFormSuccess"
      @closeModal="closeModal"
      @modal-closed="closeModal"
    />
    <DeleteVehicleModel
      v-if="isDeleteModalVisible"
      :vehicle-model="vehicleModel"
      :callback="onFormSuccess"
      @closeModal="closeModal"
      @modal-closed="closeModal"
    />
  </div>
</template>

