<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
  ImageTemplate,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
    ImageTemplate,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.vehicleCategories, {
      deleteCategoryStatus: state => state.deleteCategory.STATUS,
    }),
  },
  methods: {
    async removeVehicleCategory() {
      await this.deleteVehicleCategory(this.category.id);
      this.$emit('closeModal');
      if (!this.deleteCategoryStatus.ERROR) {
        this.$notify({
          message: 'Vehicle category successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
        this.onSuccess();
      }
    },
    ...mapActions(DOMAINS_MODEL.fleet.vehicleCategories, [
      'deleteVehicleCategory',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteVehicleCategory"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        title="Delete this category?"
        :text="`#${category.id} ${category.internalName}`"
      />
      <ImageTemplate
        :url="category.image"
        :height="200"
        class="text-center mt-3"
      />
    </template>
    <template #footer>
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteCategoryStatus.LOADING"
        @click="removeVehicleCategory"
      />
    </template>
  </GenericModalComponent>
</template>
