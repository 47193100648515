<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import mapKeys from 'lodash/mapKeys';
import upperFirst from 'lodash/upperFirst';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapState } from 'vuex';
import {
  base64WithoutName,
  getValue,
  toBoolean,
  VALIDATION_PATTERN,
} from '@emobg/web-utils';
import { PATTERN_INPUT_VALIDATIONS, toNumberUtil } from '@/utils';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import {
  CancelButton,
  DragFileComponent,
  GenericModalComponent,
  StoreNotificationComponent,
  TranslatableFieldComponent,
} from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { DEFAULT_LANGUAGE } from '@/components/TranslatableField/TranslatableFieldComponent.const';
import { scopes } from '../store/VehicleCategoriesModule';

export default {
  name: 'CategoryForm',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiAlgoliaSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
    DragFileComponent,
    TranslatableFieldComponent,
  },
  props: {
    category: {
      type: Object,
      default: null,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormReady: false,
      originalInputs: {},
      isFormValid: false,
      currentImages: [],
      files: [],
      language: DEFAULT_LANGUAGE,
      canPromoteCategory: false,
      selectedOperatorUuid: '',
      inputs: {
        active: false,
        internalName: '',
        order: null,
        image: null,
        csOperatorFk: null,
        maximumMileage: null,
        nameTranslations: {},
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.fleet.vehicleCategories, {
      categoryStatus: state => state.vehicleCategory.STATUS,
      operatorConfigStatus: state => state.operatorConfig.STATUS,
      promoteVehiclesInTheSearchResult: state => getValue(state, 'operatorConfig.data.configuration.promoteVehiclesInTheSearchResult', 0),
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
    isEditing() {
      return !!this.category;
    },
    hasSameValues() {
      return this.isEditing ? isEqual(this.inputs, this.originalInputs) : false;
    },
    modalTitle() {
      return this.isEditing ? 'Edit category' : 'Add new category';
    },
    useStoreMessage() {
      return this.isEditing && get(this, 'originalInputs.active') && !this.inputs.active;
    },
  },
  watch: {
    async selectedOperatorUuid() {
      if (this.selectedOperatorUuid) {
        await this.getOperatorConfig(this.selectedOperatorUuid);
      }
    },
    promoteVehiclesInTheSearchResult(value) {
      if (!value) {
        this.inputs.maximumMileage = null;
      }
    },
  },
  async created() {
    this.scopes = scopes;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.csOperatorsIndex = ALGOLIA_INDEXES.csOperators;

    if (this.isEditing) {
      await this.getOperatorConfig(this.category.csOperatorUuid);
      this.canPromoteCategory = this.category.maximumMileage && this.promoteVehiclesInTheSearchResult;
      this.inputs.maximumMileage = this.category.maximumMileage;
      this.inputs.internalName = this.category.internalName;
      this.inputs.order = this.category.order;
      this.inputs.active = toBoolean(this.category.active);
      this.inputs.csOperatorFk = this.category.csOperatorFk;
      this.inputs.nameTranslations = mapKeys(cloneDeep(this.category.nameTranslations), (_value, key) => `name${upperFirst(key)}`);
      this.currentImages = [{ name: `${this.inputs.internalName}.png`, src: get(this, 'category.image') }];
      this.originalInputs = cloneDeep(this.inputs);
    } else {
      this.inputs.csOperatorFk = this.activeOperatorId;
    }

    this.isFormReady = true;
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.fleet.vehicleCategories, [
      'putVehicleCategory',
      'postVehicleCategory',
      'getOperatorConfig',
    ]),
    toNumberUtil,
    isValidMileage(value) {
      const result = { isValid: true, message: '' };
      if (toNumberUtil(value) <= 0) {
        result.isValid = false;
        result.message = 'Entered value must be >0 kms';
      } else if (!(VALIDATION_PATTERN.numberNotZero.test(value))) {
        result.isValid = false;
        result.message = 'Only whole numbers allowed';
      }

      return result;
    },
    onFilesChange() {
      this.inputs.image = this.files.length ? base64WithoutName(this.files[0]) : null;
      this.currentImages = this.files.length ? [] : this.currentImages;
    },
    onChangePromoteToggle({ detail }) {
      this.canPromoteCategory = detail;

      if (!this.canPromoteCategory) {
        this.inputs.maximumMileage = null;
      }
    },
    async categoryRequest() {
      const method = this.isEditing ? this.putVehicleCategory : this.postVehicleCategory;
      const paramsToOmit = ['nameTranslations'];

      if (!this.inputs.image) {
        paramsToOmit.push('image');
      }

      const inputs = { ...omit(this.inputs, paramsToOmit), ...this.inputs.nameTranslations };
      inputs.maximumMileage = inputs.maximumMileage ? toNumberUtil(inputs.maximumMileage) : null;
      const data = this.isEditing ? { categoryId: this.category.id, data: inputs } : inputs;
      const action = this.isEditing ? 'edited' : 'added';

      await method(data);

      if (!this.categoryStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: `Vehicle category successfully <span class="emobg-font-weight-semibold">${action}</span>`,
          textAction: '',
        });

        this.onSuccess();
      }
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :size="SIZES.large"
    :title="modalTitle"
    class="CategoryForm"
    data-test-id="category-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.vehicleCategories"
        :scope="scopes.vehicleCategory"
        :is-editing="isEditing"
        :use-message="useStoreMessage"
        element="vehicle category"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        v-if="isFormReady"
        @areAllValid="valid => isFormValid = valid"
      >
        <div class="row">
          <div class="col-6 mb-3">
            <ui-toggle
              :value="inputs.active"
              :text="inputs.active ? 'Active' : 'Inactive'"
              label="Status"
              data-test-id="active-toggle"
              name="active"
              @changevalue="({ detail }) => inputs.active = detail"
            />
          </div>

          <div class="col-6 mb-3">
            <MuiInputText
              v-model="inputs.internalName"
              v-validate="{
                isRequired: true,
              }"
              :disabled="isEditing"
              label="Internal name*"
              class="w-100 mb-3"
              name="internalName"
              placeholder="Enter internal name"
              data-test-id="internal_name-input"
            />
          </div>

          <div class="col-12 mb-3">
            <label class="d-block emobg-font-weight-semibold mb-1">
              Category picture
            </label>
            <DragFileComponent
              v-model="files"
              :existing-files="currentImages"
              :draggable-height="200"
              show-preview
              @change="onFilesChange"
              @existingFileRemoved="files => currentImages = files"
            />
          </div>

          <div class="col-6 mb-3">
            <MuiAlgoliaSelect
              v-model="inputs.csOperatorFk"
              v-validate="{
                isRequired: true,
              }"
              :title="operator => operator.name"
              :index="csOperatorsIndex"
              :filters="`id:${activeOperatorId} OR parent_cs_operator_id:${activeOperatorId}`"
              label="Operator*"
              placeholder="Select operator"
              class="w-100"
              style="margin-bottom: 10px;"
              path-value="id"
              name="operator"
              data-test-id="operator-select"
              @selected="({ uuid }) => selectedOperatorUuid = uuid"
            />
          </div>

          <div class="col-6 mb-3">
            <MuiInputText
              v-model="inputs.order"
              v-validate="{
                isRequired: true,
              }"
              type="number"
              label="Display order (App/Webapp)*"
              class="w-100"
              name="order"
              placeholder="Enter a number value for display order"
              data-test-id="order-input"
            />
          </div>

          <div class="col-12">
            <TranslatableFieldComponent
              v-model="inputs.nameTranslations"
              :language="language"
              :placeholder="`Enter name (${language})`"
              is-input-text
              prefix="name"
              label="Name"
              data-test-id="name-input"
              class="row"
              @update:language="selectedLanguage => language = selectedLanguage"
            />
          </div>

          <ui-skeleton
            v-if="operatorConfigStatus.IDLE || operatorConfigStatus.LOADING"
            height="48"
            class="col-12 mt-2"
          />
          <div
            v-else-if="promoteVehiclesInTheSearchResult"
            class="col-12 mt-2"
          >
            <p class="emobg-font-weight-semibold emobg-font-medium mb-3">
              Vehicle promotion rules
            </p>
            <div class="emobg-border-1 emobg-border-radius-large emobg-border-color-ground-light p-3">
              <ui-toggle
                :value="canPromoteCategory"
                text="Promote vehicle category in the search results"
                data-test-id="promote-toggle"
                name="promoteCategory"
                @changevalue="onChangePromoteToggle"
              />

              <div
                v-if="canPromoteCategory"
                class="mt-3"
              >
                <p class="d-flex align-items-center emobg-font-weight-semibold mb-1 ml-1">
                  Maximum mileage*
                  <ui-tooltip
                    tooltip="Below the maximum mileage, this vehicle category will appear first in the search results. Above that, it will not be suggested to users"
                    class="ml-1 text-center"
                  >
                    <ui-icon
                      :icon="ICONS.infoFull"
                      :size="ICONS_SIZES.medium"
                      class="emobg-color-ink-light emobg-color-ink-hover"
                    />
                  </ui-tooltip>
                </p>
                <MuiInputText
                  v-model="inputs.maximumMileage"
                  v-validate="{
                    isRequired: true,
                    isValidMileage,
                  }"
                  class="w-100"
                  name="mileage"
                  placeholder="Enter a number value for kms"
                  data-test-id="maximumMileage-input"
                />
              </div>
            </div>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton
          data-test-id="close_modal-button"
          @click="$emit('closeModal')"
        />

        <ui-button
          :disabled="hasSameValues || !isFormValid"
          :loading="categoryStatus.LOADING"
          class="wmin-initial"
          data-test-id="save-button"
          @clickbutton="categoryRequest"
        >
          {{ isEditing ? 'Save' : 'Add' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
<style lang="scss">
.CategoryForm {
  .TranslatableFieldComponent {
    & > * {
      flex: 1 1 50%;
      padding: 0 8px;
    }
  }
}
</style>
