var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "BrandsListView",
      attrs: { "data-test-id": "brands_list-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-between mb-2",
        },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [
            _vm._v(" Vehicle brands "),
          ]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "open_form-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isFormOpened = true
                },
              },
            },
            [_vm._v(" Add new brand ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: {
                  label: "Loading brands list...",
                  absolute: "",
                  "data-test-id": "loader",
                },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "brandsList",
            attrs: {
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.exportColumns,
              index: _vm.ALGOLIA_INDEXES.vehicleBrands,
              "table-config": _vm.contentCells,
              "data-test-id": "list",
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isFormOpened
        ? _c("BrandForm", {
            attrs: {
              "is-preview": _vm.isPreviewMode,
              brand: _vm.brand,
              "on-success": _vm.refreshList,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteFormOpened
        ? _c("DeleteBrand", {
            attrs: { brand: _vm.brand, "on-success": _vm.refreshList },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }