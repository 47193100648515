var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "CategoriesListView",
      attrs: { "data-test-id": "categories_list-view" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-between mb-2",
        },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [
            _vm._v(" Vehicle categories "),
          ]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "open_form-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isFormOpened = true
                },
              },
            },
            [_vm._v(" Add new category ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: {
                  label: "Loading categories list...",
                  absolute: "",
                  "data-test-id": "loader",
                },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "categoriesList",
            attrs: {
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.contentCells,
              index: _vm.ALGOLIA_INDEXES.vehicleCategories,
              "table-config": _vm.contentCells,
              filters: _vm.getOperatorFilter({
                attribute: "cs_operator_uuid",
                searchUsing: "uuid",
              }),
              "data-test-id": "list",
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isFormOpened
        ? _c("CategoryForm", {
            attrs: { category: _vm.category, "on-success": _vm.refreshList },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteFormOpened
        ? _c("DeleteCategory", {
            attrs: { category: _vm.category, "on-success": _vm.refreshList },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isTariffsFormOpened
        ? _c("UpdateTariffsForm", {
            attrs: { category: _vm.category, "on-success": _vm.refreshList },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }