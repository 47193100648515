<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { base64WithoutName, sentenceCase } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import {
  CancelButton,
  DragFileComponent,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import {
  PATTERN_INPUT_VALIDATIONS,
  toNumberUtil as toNumber,
} from '@/utils';
import AlgoliaOptionsComponent from './AlgoliaOptionsComponent';
import { scopes as VEHICLE_MODELS_SCOPES } from '../store/VehicleModelsModule';

import {
  FUEL_TYPES,
  VEHICLE_TYPES,
} from '../const/index.const';

export default {
  name: 'VehicleModelFormComponent',
  directives: {
    Validate,
  },
  components: {
    AlgoliaOptionsComponent,
    CancelButton,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiValidationWrapper,
    StoreNotificationComponent,
    DragFileComponent,
  },
  props: {
    vehicleModelUuid: {
      type: String,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isInitialized: false,
      currentImages: [],
      files: [],
      originalInputs: null,
      inputs: {
        vehicleBrandUuid: null,
        name: null,
        vehicleType: null,
        fuelType: null,
        doorsNumber: null,
        year: null,
        batteryDangerLevel: null,
        batteryCriticalLevel: null,
        chargeTypes: [],
        tractionBatteryCapacity: null,
        tractionBatteryDangerLevel: null,
        tractionBatteryCriticalLevel: null,
        picture: null,
      },
    };
  },
  computed: {
    modalTitle() {
      if (this.isInitialized) {
        return this.vehicleModelUuid
          ? `Edit model ${get(this, 'vehicleModel.vehicleBrand.name')} ${get(this, 'vehicleModel.name')} (Id ${get(this, 'vehicleModel.id')})`
          : 'Add model';
      }

      return '';
    },
    isElectricOrHybrid() {
      return [FUEL_TYPES.electric, FUEL_TYPES.plugInHybrid].includes(this.inputs.fuelType);
    },
    areRequiredFieldsFilled() {
      switch (this.inputs.fuelType) {
        case FUEL_TYPES.electric:
          return this.inputs.vehicleBrandUuid
            && this.inputs.name
            && this.inputs.vehicleType
            && this.inputs.fuelType
            && this.inputs.doorsNumber
            && this.inputs.year
            && this.inputs.batteryDangerLevel
            && this.inputs.batteryCriticalLevel
            && this.inputs.chargeTypes.length
            && this.inputs.tractionBatteryCapacity
            && this.inputs.tractionBatteryDangerLevel
            && this.inputs.tractionBatteryCriticalLevel
            && (this.inputs.picture || this.currentImages.length)
            && this.isFormValid;
        case FUEL_TYPES.plugInHybrid:
          return this.inputs.vehicleBrandUuid
            && this.inputs.name
            && this.inputs.vehicleType
            && this.inputs.fuelType
            && this.inputs.doorsNumber
            && this.inputs.year
            && this.inputs.batteryDangerLevel
            && this.inputs.batteryCriticalLevel
            && this.inputs.chargeTypes.length
            && (this.inputs.picture || this.currentImages.length)
            && this.isFormValid;
        default:
          return this.inputs.vehicleBrandUuid
            && this.inputs.name
            && this.inputs.vehicleType
            && this.inputs.fuelType
            && this.inputs.doorsNumber
            && this.inputs.year
            && this.inputs.batteryDangerLevel
            && this.inputs.batteryCriticalLevel
            && (this.inputs.picture || this.currentImages.length)
            && this.isFormValid;
      }
    },
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
    ...mapState(DOMAINS_MODEL.fleet.vehicleModels, {
      newModelStatus: state => state.newModel.STATUS,
      newModelData: state => state.newModel.data,
      vehicleModel: state => state.detail.data,
    }),
  },
  async created() {
    this.VEHICLE_MODELS_SCOPES = VEHICLE_MODELS_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.VEHICLE_TYPES = VEHICLE_TYPES;
    this.FUEL_TYPES = FUEL_TYPES;
    this.sentenceCase = sentenceCase;
    this.toNumber = toNumber;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;
    this.clearErrors();
    if (this.vehicleModelUuid) {
      await this.getVehicleModel(this.vehicleModelUuid);
      this.inputs.name = get(this, 'vehicleModel.name');
      this.inputs.vehicleBrandUuid = get(this, 'vehicleModel.vehicleBrand.uuid');
      this.inputs.vehicleType = get(this, 'vehicleModel.vehicleType');
      this.inputs.fuelType = get(this, 'vehicleModel.fuelType');
      this.inputs.doorsNumber = get(this, 'vehicleModel.doorsNumber');
      this.inputs.year = get(this, 'vehicleModel.year');
      this.inputs.batteryDangerLevel = get(this, 'vehicleModel.batteryDangerLevel');
      this.inputs.batteryCriticalLevel = get(this, 'vehicleModel.batteryCriticalLevel');
      this.inputs.chargeTypes = (get(this, 'vehicleModel.chargeTypes') || []).map(chargeType => chargeType.uuid);
      this.inputs.tractionBatteryCapacity = get(this, 'vehicleModel.tractionBatteryCapacity');
      this.inputs.tractionBatteryDangerLevel = get(this, 'vehicleModel.tractionBatteryDangerLevel');
      this.inputs.tractionBatteryCriticalLevel = get(this, 'vehicleModel.tractionBatteryCriticalLevel');
      this.currentImages = [{ name: `${this.inputs.name}.png`, src: get(this, 'vehicleModel.picture') }];
      this.originalInputs = cloneDeep(this.inputs);
    }
    this.isInitialized = true;
  },
  methods: {
    map,
    async request() {
      this.clearErrors();
      const request = this.vehicleModelUuid ? this.putVehicleModel : this.postVehicleModel;
      const params = this.vehicleModelUuid ? { vehicleModelUuid: this.vehicleModelUuid, data: this.inputs } : this.inputs;
      await request(params);

      if (!this.newModelStatus.ERROR) {
        const { id } = this.newModelData;
        const message = this.vehicleModelUuid
          ? 'Model edited successfully!'
          : `Model added successfully with <span class="emobg-font-weight-bold">Id ${id}.</span>`;
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.callback(id);
        this.resetData();
      }
    },
    onFilesChange() {
      this.inputs.picture = this.files.length ? base64WithoutName(this.files[0]) : null;
      this.currentImages = this.files.length ? [] : this.currentImages;
    },
    ...mapActions(DOMAINS_MODEL.fleet.vehicleModels, [
      'putVehicleModel',
      'postVehicleModel',
      'getVehicleModel',
    ]),
    ...mapMutations(DOMAINS_MODEL.fleet.vehicleModels, [
      'resetData',
      'clearErrors',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :title="modalTitle"
    :header="{ isClosable: true }"
    class="VehicleModelFormComponent"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.fleet.vehicleModels"
        :scope="VEHICLE_MODELS_SCOPES.newModel"
        :is-editing="!!vehicleModelUuid"
        element="model"
      />
    </template>
    <template slot="body">
      <MuiValidationWrapper @areAllValid="isValid => isFormValid = isValid">
        <div class="row">
          <div class="col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none">
            <div class="pr-2">
              <label class="d-block emobg-font-weight-semibold mb-1">
                Vehicle brand*
              </label>
              <MuiAlgoliaSelect
                v-if="isInitialized"
                v-model="inputs.vehicleBrandUuid"
                :index="ALGOLIA_INDEXES.vehicleBrands"
                :title="brand => brand.name"
                name="brandUuid"
                no-cache
                path-value="uuid"
                placeholder="Select a vehicle brand"
                class="w-100 mb-4"
              />
              <ui-skeleton
                v-else
                class="mb-4"
              />
              <label class="d-block emobg-font-weight-semibold mb-1">
                Model name*
              </label>
              <MuiInputText
                v-if="isInitialized"
                v-model="inputs.name"
                placeholder="Enter a model name"
                name="name"
                class="mb-4 w-100"
              />
              <ui-skeleton
                v-else
                class="mb-4"
              />
              <ui-select
                v-if="isInitialized"
                :value="inputs.vehicleType"
                :options.prop="map(VEHICLE_TYPES, (value) => ({ label: sentenceCase(value), value }))"
                :color="GRAYSCALE.groundLighter"
                label="Vehicle type*"
                placeholder="Select a vehicle type"
                name="vehicleType"
                class="w-100 d-block mb-4"
                @selectoption="({ detail }) => inputs.vehicleType = detail"
              />
              <ui-skeleton
                v-else
                class="mb-4"
              />
              <ui-select
                v-if="isInitialized"
                :value="inputs.fuelType"
                :disabled="!!vehicleModelUuid"
                :options.prop="map(FUEL_TYPES, (value) => ({ label: sentenceCase(value), value }))"
                :color="GRAYSCALE.groundLighter"
                label="Fuel type*"
                placeholder="Select a fuel type"
                name="fuelType"
                class="d-block w-100 mb-4"
                @selectoption="({ detail }) => inputs.fuelType = detail"
              />
              <ui-skeleton
                v-else
                class="mb-4"
              />
              <label class="d-block emobg-font-weight-semibold mb-1">
                Doors number*
              </label>
              <div
                v-if="isInitialized"
                class="mb-4"
              >
                <MuiInputText
                  v-model.number="inputs.doorsNumber"
                  v-validate="{
                    isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                  }"
                  :value="inputs.doorsNumber"
                  placeholder="Enter a number value for doors"
                  class="w-100"
                  name="doors"
                />
              </div>
              <ui-skeleton
                v-else
                class="mb-4"
              />
              <label class="d-block emobg-font-weight-semibold mb-1">
                Year*
              </label>
              <div
                v-if="isInitialized"
                class="mb-4"
              >
                <MuiInputText
                  v-model.number="inputs.year"
                  v-validate="{
                    isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                  }"
                  placeholder="Enter a number value for year"
                  class="w-100"
                  name="year"
                />
              </div>
              <ui-skeleton
                v-else
                class="mb-4"
              />
              <label class="d-block emobg-font-weight-semibold mb-1">
                Vehicle picture*
              </label>
              <DragFileComponent
                v-if="isInitialized"
                v-model="files"
                :existing-files="currentImages"
                show-preview
                @change="onFilesChange"
                @existingFileRemoved="files => currentImages = files"
              />
              <ui-skeleton
                v-else
                class="mb-4"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="pl-2">
              <label class="d-block emobg-font-weight-semibold mb-1">
                Battery danger level*
              </label>
              <div
                v-if="isInitialized"
                class="mb-4"
              >
                <MuiInputText
                  v-model="inputs.batteryDangerLevel"
                  v-validate="{
                    isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
                  }"
                  placeholder="Enter a number value for volts"
                  class="w-100"
                  name="batteryDangerLevel"
                  @blur="value => inputs.batteryDangerLevel = toNumber(value)"
                />
              </div>
              <ui-skeleton
                v-else
                class="mb-4"
              />
              <label class="d-block emobg-font-weight-semibold mb-1">
                Battery critical level*
              </label>
              <div
                v-if="isInitialized"
                class="mb-4"
              >
                <MuiInputText
                  v-model="inputs.batteryCriticalLevel"
                  v-validate="{
                    isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
                  }"
                  placeholder="Enter a number value for volts"
                  class="w-100"
                  name="batteryCriticalLevel"
                  @blur="value => inputs.batteryCriticalLevel = toNumber(value)"
                />
              </div>
              <ui-skeleton
                v-else
                class="mb-4"
              />
              <div
                v-show="isElectricOrHybrid"
                class="mb-4"
              >
                <label class="d-block emobg-font-weight-semibold mb-1">
                  Supported charge types*
                </label>
                <AlgoliaOptionsComponent
                  v-model="inputs.chargeTypes"
                  :label="option => option.name"
                  :index="ALGOLIA_INDEXES.chargeTypes"
                  path-value="uuid"
                />
              </div>

              <template v-if="isElectricOrHybrid">
                <label class="d-block emobg-font-weight-semibold mb-1">
                  Traction battery capacity*
                </label>
                <div class="mb-4">
                  <MuiInputText
                    v-model.number="inputs.tractionBatteryCapacity"
                    v-validate="{
                      isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                    }"
                    placeholder="Enter a number value for kW"
                    class="w-100"
                    name="tractionBatteryCapacity"
                  />
                </div>
              </template>

              <template v-if="inputs.fuelType === FUEL_TYPES.electric">
                <label class="d-block emobg-font-weight-semibold mb-1">
                  Traction battery danger level*
                </label>
                <div class="mb-4">
                  <MuiInputText
                    v-model.number="inputs.tractionBatteryDangerLevel"
                    v-validate="{
                      isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                    }"
                    placeholder="Enter a number value for percentage"
                    class="w-100"
                    name="tractionBatteryDangerLevel"
                  />
                </div>
              </template>

              <template v-if="inputs.fuelType === FUEL_TYPES.electric">
                <label class="d-block emobg-font-weight-semibold mb-1">
                  Traction battery critical level*
                </label>
                <div class="mb-4">
                  <MuiInputText
                    v-model.number="inputs.tractionBatteryCriticalLevel"
                    v-validate="{
                      isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                    }"
                    placeholder="Enter a number value for percentage"
                    class="w-100"
                    name="tractionBatteryCriticalLevel"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <div class="d-flex justify-content-center">
          <CancelButton @click="$emit('closeModal')" />
        </div>

        <div class="d-flex justify-content-center">
          <ui-button
            :disabled="!areRequiredFieldsFilled || hasSameValues"
            :loading="newModelStatus.LOADING"
            class="wmin-initial"
            @clickbutton="request"
          >
            Save
          </ui-button>
        </div>
      </div>
    </template>
  </GenericModalComponent>
</template>
